<template>
  <div>
    <layout-vertical>

      <div v-if="isUserAdmin || !isUserDataFetched">
        <router-view />
      </div>
      <div v-else>
        <Error403 />
      </div>

      <template #navbar="{ toggleVerticalMenuActive }">
        <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />

      </template>

    </layout-vertical>
  </div>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import Navbar from '../components/Navbar.vue'
import Error403 from '@/views/error/Error403.vue'

export default {
  components: {
    Error403,
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
      play: false,
      selectedGroupId: null,
    }
  },
  computed: {
    lightBoxData() {
      return this.$store.getters['lightBox/getLightBoxData']
    },
    isUserAdmin() {
      return this.$store.getters['user/isUserAdmin']
    },
    isUserDataFetched() {
      return this.$store.getters['user/isUserDataFetched']
    },
  },
  watch: {
    lightBoxData() {
      if (this.lightBoxData) {
        document.documentElement.style.overflow = 'hidden'
        return
      }
      document.documentElement.style.overflow = 'auto'
    },
  },
}
</script>
